import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import SEO from '../components/seo'
import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'
import { useTranslations } from '../hooks/use-translations'
import { PathEnum } from '../types/pages'

import * as s from './pages.module.scss'

interface IPrivacyStatement {
  title: string
  subtitle?: string[]
  text?: string[]
}

const privacyStatement: IPrivacyStatement[] = [
  {
    title: '_privacy_title',
    subtitle: ['_privacy_subtitle'],
    text: ['_privacy_lead1.1', '_privacy_lead1.2'],
  },
  {
    title: '_privacy_title1',
    text: [
      '_privacy_lead2.1',
      '_privacy_lead2.2',
      '_privacy_lead2.3',
      '_privacy_lead2.4',
      '_privacy_lead2.5',
      '_privacy_lead2.6',
      '_privacy_lead2.7',
      '_privacy_lead2.8',
      '_privacy_lead2.9',
      '_privacy_lead2.10',
      '_privacy_lead2.11',
      '_privacy_lead2.12',
      '_privacy_lead2.13',
    ],
  },
  {
    title: '_privacy_title2',
    text: [
      '_privacy_lead3.1',
      '_privacy_lead3.2',
      '_privacy_lead3.3',
      '_privacy_lead3.4',
      '_privacy_lead3.5',
      '_privacy_lead3.6',
    ],
  },
  {
    title: '_privacy_title3',
    text: ['_privacy_lead4.1', '_privacy_lead4.2', '_privacy_lead4.3'],
  },
  {
    title: '_privacy_title4',
    text: ['_privacy_lead5.1', '_privacy_lead5.2'],
  },
  {
    title: '_privacy_title5',
    subtitle: ['_privacy_subtitle5'],
    text: [
      '_privacy_lead6.1',
      '_privacy_lead6.2',
      '_privacy_lead6.3',
      '_privacy_lead6.4',
    ],
  },
  {
    title: '_privacy_title6',
    text: ['_privacy_lead7.1', '_privacy_lead7.2'],
  },
  {
    title: '_privacy_title7',
    text: ['_privacy_lead8.1', '_privacy_lead8.2', '_privacy_lead8.3'],
  },
]

const privacyStatementUs: IPrivacyStatement[] = [
  {
    title: '_privacy_title',
    subtitle: ['_privacy_subtitle'],
  },
  {
    title: '_privacy_title1',
    subtitle: ['_privacy_subtitle1'],
    text: ['_privacy_lead', '_privacy_lead1', '_privacy_lead2'],
  },
  {
    title: '_privacy_title2',
    subtitle: ['_privacy_subtitle2'],
    text: [
      '_privacy_lead3',
      '_privacy_lead4',
      '_privacy_lead5',
      '_privacy_lead6',
      '_privacy_lead7',
      '_privacy_lead8',
      '_privacy_lead9',
      '_privacy_lead10',
      '_privacy_lead11',
    ],
  },
  {
    title: '_privacy_title3',
    subtitle: ['_privacy_subtitle3'],
  },
  {
    title: '_privacy_title4',
    subtitle: ['_privacy_subtitle4'],
  },
  {
    title: '_privacy_title5',
    subtitle: ['_privacy_subtitle5'],
  },
  {
    title: '_privacy_title6',
    subtitle: ['_privacy_subtitle6'],
  },
  {
    title: '_privacy_title7',
    subtitle: ['_privacy_subtitle7'],
    text: ['_privacy_lead12', '_privacy_lead13', '_privacy_lead14'],
  },
  {
    title: '_privacy_title8',
    subtitle: ['_privacy_subtitle8'],
  },
  {
    title: '_privacy_title9',
    subtitle: ['_privacy_subtitle9'],
  },
  {
    title: '_privacy_title10',
    subtitle: [
      '_privacy_subtitle10',
      '_privacy_subtitle11',
      '_privacy_subtitle12',
      '_privacy_subtitle13',
    ],
  },
  {
    title: '_privacy_title11',
    subtitle: ['_privacy_subtitle14'],
  },
  {
    title: '_privacy_title12',
    subtitle: ['_privacy_subtitle15', '_privacy_subtitle16'],
  },
  {
    title: '_privacy_title13',
    subtitle: ['_privacy_subtitle17'],
    text: [
      '_privacy_lead15',
      '_privacy_lead16',
      '_privacy_lead17',
      '_privacy_lead18',
      '_privacy_lead19',
      '_privacy_lead20',
    ],
  },
  {
    title: '_privacy_title14',
    subtitle: ['_privacy_subtitle18'],
  },
  {
    title: '_privacy_title15',
    subtitle: ['_privacy_subtitle19', '_privacy_subtitle20'],
  },
]

const MODULE_NAME = 'Privacy'

const PrivacyModulePage: React.FC = () => {
  const { region } = React.useContext(GlobalContext)
  const { t, getPath } = useTranslations()

  function renderLink(text: string) {
    const emailOrUrlRegex = /(https?:\/\/[^\s]+)(.)|(\S+@\S+\.\S+)(.)/
    const linkRegex = new RegExp(emailOrUrlRegex)

    return text
      .split(emailOrUrlRegex)
      .filter(text => text !== '')
      .map(text =>
        linkRegex.test(String(text).toLowerCase()) ? (
          <a
            className="has-text-primary"
            href={text === t('info_email') ? `mailto:${text}` : text}
          >
            {text}
          </a>
        ) : (
          text
        )
      )
  }

  return (
    <>
      <SEO title={MODULE_NAME} />

      <section className="px-5 pb-16 pt-18">
        <div className="container">
          <h1 className="title is-size-2 has-text-primary whitespace-pre-line is-uppercase">
            {t('Privacy statement')}
          </h1>
          {region === AppRegionEnum.Us && (
            <>
              <p>
                This is the privacy statement of the company SIGNAX SOFTWARE
                DESIGN LLC. This document explains how SIGNAX handles the
                processing of your personal data. SIGNAX takes the greatest
                possible care when processing your personal data.
              </p>
              <h2 className={s.privacy_statement__title}>
                LICENSE AND SERVICES AGREEMENT (TERMS OF USE):{' '}
                <Link className="has-text-primary" to={getPath(PathEnum.Eula)}>
                  https://signax.io/eula
                </Link>
              </h2>
            </>
          )}

          {(region === AppRegionEnum.Us
            ? privacyStatementUs
            : privacyStatement
          ).map(item => (
            <>
              <h2 className={s.privacy_statement__title}>{t(item.title)}</h2>
              {item.subtitle &&
                item.subtitle.map((subtitle, idx) => (
                  <p key={`${subtitle}_${idx}`}>{renderLink(t(subtitle))}</p>
                ))}
              {item.text && (
                <ul
                  className={cn('ml-6 mt-5', {
                    [s.privacy_statement__list]: region === AppRegionEnum.Us,
                  })}
                >
                  {item.text.map((text, idx) => (
                    <li key={`${text}_${idx}`}>{renderLink(t(text))}</li>
                  ))}
                </ul>
              )}
            </>
          ))}
        </div>
      </section>
    </>
  )
}

export default PrivacyModulePage
